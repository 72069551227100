<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <a class="btn btn-primary align-self-center" v-if="branchCourseID > 0" v-on:click="$router.back()">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg" />
            </span>
            {{ $t("pages.module.branchCourse.reservation.back") }}
        </a>
        <router-link to="/module/branch-course" class="btn btn-primary align-self-center ms-3" v-else>
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg" />
            </span>
            {{ $t("pages.module.branchCourse.managementTitle") }}
        </router-link>
        <button class="btn btn-danger align-self-center ms-3" :data-kt-indicator="exportLoading ? 'on' : null" :disabled="exportLoading" v-on:click="exportReservation">
            <span v-if="!exportLoading" class="indicator-label">
                <span class="svg-icon svg-icon-1">
                    <inline-svg src="/media/icons/duotune/files/fil021.svg" />
                </span>
                {{ $t("common.export") }}
            </span>

            <span v-if="exportLoading" class="indicator-progress">
                <span class="spinner-border spinner-border-sm align-middle me-2"></span>
                {{ $t("messages.wait") }}
            </span>
        </button>
    </div>
    <custom-table
        :title="$t('pages.module.branchCourse.reservation.title')"
        :subTitle="$t('pages.module.branchCourse.reservation.subTitle')"
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        selectableRows
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        filterComponentName="ModuleBranchCourseReservationFilter"
        @action="handleClickAction"
        @selectableRows="handleSelectedRow"
        @changeTable="handleTableChange">
        <template v-slot:branch_course="{ row: record }">
            {{ record.course.title + ' (' + record.course.branch.title + ')' }}
        </template>
        <template v-slot:name="{ row: record }">
            {{ record.firstname + ' ' + record.lastname }}
        </template>
        <template v-slot:email="{ row: record }">
            <a :href="'mailto:' + record.email" class="text-gray-600 text-hover-primary mb-1" v-if="record.email">
                {{ record.email }}
            </a>
            <span v-else>-</span>
        </template>
        <template v-slot:mobilePhone="{ row: record }">
            <a :href="'tel:' + record.mobile_phone" class="text-gray-600 text-hover-primary mb-1" v-if="record.mobile_phone">
                {{ record.mobile_phone }}
            </a>
            <span v-else>-</span>
        </template>
        <template v-slot:approved="{ row: record }">
            <span :class="record.approved ? 'badge-light-success': 'badge-light-danger'" class="badge">{{ record.approved ? $t('common.yes') : $t('common.no') }}</span>
        </template>
        <template v-slot:created_at="{ row: record }">
            {{ $moment(record.created_at).format("DD.MM.YYYY - HH:mm:ss") }}
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <a v-on:click="fetchReservation(record, true)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/general/gen004.svg" />
                    </span>
                </a>
                <a v-on:click="fetchReservation(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/art/art005.svg" />
                    </span>
                </a>
                <el-tooltip :content="$t('pages.module.branchCourse.reservation.' + (record.active_user_block_data ? 'unblockUser' : 'blockUser.title'))" placement="bottom" v-if="record.user_id">
                    <a v-on:click="unBlockUser(record)" class="btn btn-icon btn-success btn-sm me-1" v-if="record.active_user_block_data">
                        <span class="svg-icon svg-icon-3">
                            <inline-svg src="/media/icons/duotune/arrows/arr012.svg" />
                        </span>
                    </a>
                    <a v-on:click="blockUser(record)" class="btn btn-icon btn-danger btn-sm me-1" v-else>
                        <span class="svg-icon svg-icon-3">
                            <inline-svg src="/media/icons/duotune/arrows/arr011.svg" />
                        </span>
                    </a>
                </el-tooltip>
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
    <div class="modal fade" id="kt_modal_reservation" ref="reservationModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_add_customer_header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmit()" :model="form.data" ref="reservationForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.branchCourse.branch.cols.status') }}</label>
                                <el-form-item prop="status">
                                    <el-radio-group v-model="form.data.approved">
                                        <el-radio-button :label="true">{{ $t("common.yes") }}</el-radio-button>
                                        <el-radio-button :label="false">{{ $t("common.no") }}</el-radio-button>
                                    </el-radio-group>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{  $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
    <div class="modal fade" id="kt_modal_reservation_detail" ref="reservationDetailModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_reservation_detail_header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_reservation_detail_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <div class="modal-body py-10 px-lg-17">
                    <div class="scroll-y me-n7 pe-7" id="kt_modal_reservation_detail_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                        <div class="fv-row mb-3" v-if="form.data.course">
                            <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.module.branchCourse.reservation.cols.branchCourse') }}:</label>
                            <span class="text-gray-500 fw-bold fs-6">{{ form.data.course.title + ' (' + form.data.course.branch.title + ')' }}</span>
                        </div>
                        <!--<div class="fv-row mb-5" v-if="form.data.user">
                            <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.module.branchCourse.reservation.cols.user') }}:</label>
                            <span class="text-gray-500 fw-bold fs-6">{{ form.data.user.full_name + ' (' + form.data.user.email +')' }}</div>
                        </div>-->
                        <div class="fv-row mb-3">
                            <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.module.branchCourse.reservation.cols.name') }}:</label>
                            <span class="text-gray-500 fw-bold fs-6">{{ form.data.firstname + ' ' + form.data.lastname }}</span>
                        </div>
                        <div class="fv-row mb-3">
                            <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.module.branchCourse.reservation.cols.email') }}:</label>
                            <a :href="'mailto:'+ form.data.email" class="fw-bold fs-6 text-gray-500 text-hover-primary">{{ form.data.email }}</a>
                        </div>
                        <div class="fv-row mb-3">
                            <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.module.branchCourse.reservation.cols.mobilePhone') }}:</label>
                            <a :href="'tel:'+ form.data.mobile_phone" class="fw-bold fs-6 text-gray-500 text-hover-primary">{{ form.data.mobile_phone }}</a>
                        </div>
                        <div class="fv-row mb-3">
                            <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.module.branchCourse.reservation.cols.ip') }}:</label>
                            <span class="text-gray-500 fw-bold fs-6">{{ form.data.ip }}</span>
                        </div>
                        <div class="fv-row mb-3">
                            <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.module.branchCourse.reservation.cols.userAgent') }}:</label>
                            <span class="text-gray-500 fw-bold fs-6">{{ form.data.user_agent }}</span>
                        </div>
                        <div class="fv-row mb-3">
                            <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.module.branchCourse.reservation.cols.approved') }}:</label>
                            <span class="text-gray-500 fw-bold fs-6">{{ form.data.approved ? $t('common.yes') : $t('common.no') }}</span>
                        </div>
                        <div class="fv-row mb-3">
                            <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.module.branchCourse.reservation.cols.createdAt') }}:</label>
                            <span class="text-gray-500 fw-bold fs-6">{{ $moment(form.data.created_at).format("DD.MM.YYYY - HH:mm:ss") }}</span>
                        </div>
                    </div>
                </div>

                <div class="modal-footer flex-center">
                    <button class="btn btn-lg btn-primary" type="button" v-on:click="hideModal($refs.reservationDetailModal)">{{ $t("btn.close") }}</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="kt_modal_block_user" ref="blockUserModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_block_user_header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_block_user_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmitBlockUser()" :model="form.data" ref="blockUserForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_block_user_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.branchCourse.reservation.blockUser.cols.reason') }}</label>
                                <el-form-item prop="reason">
                                    <el-input v-model="form.data.reason" type="textarea" rows="3" maxlength="512"/>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">
                                    {{ $t('pages.module.branchCourse.reservation.blockUser.cols.blockedAt') }}
                                    <el-tooltip class="item ms-1" popper-class="max-w-300px" effect="dark" :content="$t('pages.module.branchCourse.reservation.blockUser.informationBoxes.blockedAt')" placement="top">
                                        <span class="svg-icon svg-icon-1">
                                            <inline-svg src="/media/icons/duotune/general/gen046.svg" />
                                        </span>
                                    </el-tooltip>
                                </label>
                                <el-form-item prop="blocked_at">
                                    <el-date-picker class="w-100" v-model="form.data.blocked_at" type="datetime" :placeholder="$t('common.chooseDateAndTime')"  format="YYYY-MM-DD HH:mm:ss" valueFormat="YYYY-MM-DD HH:mm:ss" clearable></el-date-picker>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{  $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "index",
    components: {
        CustomTable
    },
    data(){
        return {
            fields: [
                {
                    name: this.$t("pages.module.branchCourse.reservation.cols.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("pages.module.branchCourse.reservation.cols.branchCourse"),
                    scopedSlots: {customRender: "branch_course"}
                },
                {
                    name: this.$t("pages.module.branchCourse.reservation.cols.name"),
                    scopedSlots: {customRender: "name"}
                },
                {
                    name: this.$t("pages.module.branchCourse.reservation.cols.email"),
                    scopedSlots: {customRender: "email"}
                },
                {
                    name: this.$t("pages.module.branchCourse.reservation.cols.mobilePhone"),
                    scopedSlots: {customRender: "mobilePhone"}
                },
                {
                    name: this.$t("pages.module.branchCourse.reservation.cols.approved"),
                    scopedSlots: {customRender: "approved"}
                },
                {
                    name: this.$t("pages.module.branchCourse.reservation.cols.ip"),
                    key: "ip",
                },
                {
                    name: this.$t("pages.module.branchCourse.reservation.cols.createdAt"),
                    scopedSlots: {customRender: "created_at"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                data: {}
            },
            selectedRowKeys: [],
            filterParameters: {},
            exportLoading: false
        }
    },
    computed: {
        table() {
            return this.$store.state.module.branchCourse.reservation.table;
        },
        branchCourseID() {
            return this.$route.query.branchCourseID;
        }
    },
    mounted(){
        this.setCurrentPageBreadcrumbs(this.$t("pages.module.branchCourse.reservation.title"), [this.$t("menu.moduleManagement"), this.$t("pages.module.branchCourse.managementTitle")]);

        this.filterParameters = {
            branch_course_id: this.branchCourseID,
            sort: 'id:desc'
        };

        this.$store.dispatch('module/branchCourse/reservation/get', {
            page: {},
            filterData: Object.assign(this.filterParameters, this.$root.getFilterWithUrl({}))
        });
    },
    methods:{
        fetchReservation(record, detail = false) {
            this.form.updateStatus = true;
            this.form.title =  this.$t("pages.module.branchCourse.reservation." + (detail ? 'reservationDetails' : 'editReservation'));
            this.axios.get(this.endpoints['module_branch_course_reservation']+ '/' + record.id).then(response => {
                this.form.data = response.data.data;
                this.showModal(this.$refs[detail ? 'reservationDetailModal' : 'reservationModal']);
            });
        },
        onSubmit(){
            this.$refs.reservationForm.validate((valid) => {
                if(valid) {
                    this.form.loading = true;

                    if(this.form.data.id) {
                        this.axios.put(this.endpoints['module_branch_course_reservation'] + '/' + this.form.data.id, this.form.data).then(response => {
                            this.onResponse(response.data, () => {
                                this.refreshTable();
                                this.hideModal(this.$refs.reservationModal);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        blockUser(record) {
            this.form.title =  this.$t("pages.module.branchCourse.reservation.blockUser.title");
            this.form.data = {
                user_id: record.user_id
            }

            this.showModal(this.$refs.blockUserModal);
        },
        onSubmitBlockUser(){
            this.$refs.blockUserForm.validate((valid) => {
                if(valid) {
                    this.form.loading = true;

                    this.axios.post(this.endpoints['module_branch_course_reservation_block'], this.form.data).then(response => {
                        this.onResponse(response.data, () => {
                            this.refreshTable();
                            this.hideModal(this.$refs.blockUserModal);
                        });
                    }).catch(error => {
                        this.onResponseFailure(error.response.data);
                    }).finally( () => {
                        this.form.loading = false;
                    });
                } else {
                    return false;
                }
            });
        },
        unBlockUser(record){
            this.$confirm(this.$t('messages.sureAction'), this.$t('messages.warning'), {
                confirmButtonText: this.$t('common.yes'),
                cancelButtonText: this.$t('common.cancel'),
                type: 'warning',
            }).then(() => {
                this.$store.dispatch("module/branchCourse/reservation/block/delete", {
                    id: [record.active_user_block_data.id],
                    refresh: false
                }).then((successDeleted) => {
                    if (successDeleted) {
                        this.refreshTable();
                    }
                });
            });
        },
        deleteRecord(id){
            this.$store.dispatch("module/branchCourse/reservation/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
            });
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        refreshTable(){
            this.$store.dispatch("module/branchCourse/reservation/refresh");
        },
        handleTableChange(pagination, filterData) {
            this.filterParameters = Object.assign({
                branch_course_id: this.branchCourseID,
                sort: 'id:desc'
            }, filterData);

            this.$store.dispatch("module/branchCourse/reservation/get", {
                page: pagination,
                filterData: this.filterParameters
            });
        },
        handleSelectedRow(record){
            this.selectedRowKeys = record;
        },
        exportReservation(){
            this.exportLoading = true;
            this.axios({
                url: this.endpoints['module_branch_course_reservation_export'],
                method: 'get',
                responseType: "blob",
                params: this.filterParameters
            }).then(response => {
                const type = response.headers['content-type']
                const blob = new Blob([response.data], {type: type, encoding: 'UTF-8'})
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.click();
            }).finally(() => {
                this.exportLoading = false;
            });
        }
    }
}
</script>

<style>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}
</style>